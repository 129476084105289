<template>
  <div>
    <b-card no-body class="mb-0">
      <!-- Loading -->
      <template v-if="spinner === true">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center m-5">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
        </div>
      </template>

      <b-card v-else no-body class="mb-0 py-2 px-5 card-company-table">
        <div v-for="nm in newsMediaDetails" :key="nm.id" class="mb-5">
          <!-- id & actions -->
          <b-row class="mb-3">
            <b-col md="6" class="mb-3 mb-md-0">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                #
              </h4>
              <h4 class="font-weight-normal">{{ nm.id }}</h4>
            </b-col>
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                Update ({{ nm.title }})
              </h4>
              <div>
                <!-- edit btn -->
                <b-button pill size="sm" variant="primary" @click="editNewsMediaData" class="mr-1"
                  v-b-modal.modal-scrollable-actions>
                  <span class="text-nowrap">Update</span>
                </b-button>

                <!-- delete btn -->
                <b-button pill size="sm" variant="danger" @click="() => {
                    isEdit = false;
                  }
                  " v-b-modal.modal-scrollable-actions>
                  <span class="text-nowrap">Delete</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <!-- title & sub_title -->
          <b-row class="mb-3">
            <b-col md="6" class="mb-3 mb-md-0">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                title :
              </h4>
              <h4 class="font-weight-normal">{{ nm.title }}</h4>
            </b-col>
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                sub title :
              </h4>
              <h4 class="font-weight-normal">{{ nm.sub_title }}</h4>
            </b-col>
          </b-row>
          <!-- category -->
          <b-row class="mb-3">
            <b-col class="mb-3 mb-md-0">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                category :
              </h4>
              <h4 class="font-weight-normal">{{ nm.category !== null && nm.category.title }}</h4>
            </b-col>

            <b-col class="mb-3 mb-md-0">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                Posted Date :
              </h4>
              <h4 class="font-weight-normal">{{ nm.posted_at }}</h4>
            </b-col>
          </b-row>

          <!-- description, photos -->
          <b-row class="mb-3">
            <b-col md="6" class="mb-3 mb-md-0">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                description :
              </h4>
              <!-- <h4 class="font-weight-normal" v-html="e.description"></h4> -->

              <b-button pill size="sm" variant="primary" v-b-modal.modal-scrollable-des>
                <span class="text-nowrap">Details</span>
              </b-button>
            </b-col>
            <!-- photos -->
            <b-col md="6">
              <h4 class="font-weight-bolder text-uppercase text-underline">
                photos :
              </h4>
              <b-button pill size="sm" variant="primary" @click="() => {
                  isPhoto = true;
                }
                " v-b-modal.modal-scrollable class="d-block">
                <span class="text-nowrap">Details</span>
              </b-button>
            </b-col>
          </b-row>
        </div>

        <div class="w-100 text-center">
          <b-button type="button" variant="primary" @click="() => router.go(-1)" class="col-md-10 col-lg-4">
            <!-- <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner> -->
            <span>Back</span>
          </b-button>
        </div>
      </b-card>
    </b-card>

    <!--Model-->
    <div>
      <b-modal v-model="myModal" id="modal-scrollable" class="modal-scrollable" size="lg" header-class="text-uppercase"
        scrollable hide-footer>
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div class="w-100 d-flex justify-content-between align-items-center">
            <h5 class="mb-0" v-if="isPhoto">Photos</h5>
            <b-button variant="outline-danger" @click="close"> X </b-button>
          </div>
        </template>
        <template v-if="isPhoto">
          <MyLightBox :items="newsMediaDetails[0]" @getUpdatePhotoId="uploadUpdatePhoto"
            @updatePhotoFromChild="updatePhoto" :postFormEditDatas="editOriVals" :fetchDetails="fetchDetails"
            :postForm="postForm" :myModal="myModal" urlName="our_news" />
        </template>
      </b-modal>
    </div>
    <!--Model-->

    <!--Edit_Del_Modal-->
    <div>
      <b-modal v-model="editDelModal" id="modal-scrollable-actions" class="modal-scrollable" :size="isEdit ? 'lg' : 'sm'"
        header-class="text-uppercase" :scrollable="isEdit && true" :centered="!isEdit && true" hide-footer>
        <template #modal-header>
          <!-- Emulate built in modal header close button action -->
          <div class="
              w-100
              d-flex
              justify-content-between
              align-items-center
              text-uppercase
            ">
            <h5 class="mb-0" v-if="!isEdit">Are you sure to delete?</h5>
            <h5 class="mb-0" v-if="isEdit">Edit News And Media</h5>
            <b-button variant="outline-danger" @click="close"> X </b-button>
          </div>
        </template>

        <form ref="form" v-if="isEdit">
          <b-form-group>
            <!-- Title & Sub_Title -->
            <div class="mb-2">
              <b-row>
                <b-col>
                  <div>
                    <label for="title" class="font-weight-bolder font-size-6">Title :</label>
                    <b-form-input v-model="postForm.title" id="title" placeholder="Enter Service Title" required>
                    </b-form-input>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="subTitle" class="font-weight-bolder">Sub Title :</label>
                    <b-form-input v-model="postForm.sub_title" id="subTitle" placeholder="Enter Service Sub Title"
                      required>
                    </b-form-input>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- Categories -->
            <div class="mb-2">
              <label for="categories" class="font-weight-bolder">Categories :</label>
              <b-form-select v-model="postForm.category_id" required>
                <b-form-select-option disabled value="">Please Select a category</b-form-select-option>
                <b-form-select-option v-for="c in categories" :key="c.id" :value="c.id">{{ c.title
                }}</b-form-select-option>
              </b-form-select>
            </div>

            <!-- Description -->
            <div class="mb-2">
              <label for="des" class="font-weight-bolder">Description :</label>
              <vue-editor :editorToolbar="quillBar" v-model="postForm.description"></vue-editor>
            </div>

            <!-- Active Status & Photos -->
            <b-row>
              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder">Status :</label>
                  <b-form-checkbox v-model="checked" @change="toggle(checked)" name="check-button" switch>
                  </b-form-checkbox>
                </div>
              </b-col>

              <b-col>
                  <div>
                    <label for="posted_date" class="font-weight-bolder">Posted Date :</label>
                    <b-form-datepicker id="posted_date" v-model="postForm.posted_at" class="mb-2"
                      required></b-form-datepicker>
                  </div>
                </b-col>

              <b-col v-if="!isEdit">
                <div>
                  <label for="photo" class="font-weight-bolder">Photos :</label>
                  <vue-upload-multiple-image @upload-success="uploadImageSuccess" @before-remove="beforeRemove"
                    @edit-image="editImage" dragText="Drag images (multiple)" browseText="(or) Select"
                    primaryText="First Photo"></vue-upload-multiple-image>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
        </form>

        <template v-if="isEdit">
          <div class="w-100 text-center">
            <b-button variant="primary" @click="updateNewsMediaData" class="col-6">
              <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
              <span v-else>Update</span>
            </b-button>
          </div>
        </template>

        <div v-if="!isEdit">
          <h4 class="text-center mb-2">Delete 1 from news and media?</h4>
          <div class="d-flex justify-content-center">
            <!-- cancel btn -->
            <b-button pill variant="primary" @click="editDelModal = false" class="mr-1">
              <span class="text-nowrap">Cancel</span>
            </b-button>

            <!-- delete btn -->
            <b-button pill variant="danger" @click="delNewsMediaData">
              <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
              <span v-else>Delete</span>
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
    <!--edit_del_Modal-->

    <DescriptionModal :desData="newsMediaDetails[0]" />
  </div>
</template>
  
<script>
import store from "@/store";
import { ref, reactive, computed, watch } from "@vue/composition-api";
import Table from "../components/Table.vue";
import MyLightBox from "../components/MyLightBox.vue";
import DescriptionModal from "../components/DescriptionModal.vue";
import { VueEditor } from "vue2-quill-editor";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { useRouter } from "@core/utils/utils";
import {
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BModal,
  BFormTextarea,
  BTabs,
  BTab,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BFormDatepicker,
} from "bootstrap-vue";
import router from "@/router";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
    BTabs,
    BTab,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    BFormDatepicker,
    Table,
    MyLightBox,
    DescriptionModal,
    VueEditor,
    VueUploadMultipleImage,
  },
  props: ["query"],
  setup(props) {
    const { router } = useRouter();
    const spinner = ref(false);
    const myModal = ref(false);
    const editDelModal = ref(false);
    const isPhoto = ref(false);
    const isEdit = ref(false);
    const pendingModal = ref(false);
    const checked = ref(true);
    const newsMediaDetails = ref([]);
    const categories = ref([]);
    const updatePhotoArr = ref([]);
    const routeId = ref(props.query.id);
    const postForm = reactive({
      id: "",
      title: "",
      category_id: "",
      sub_title: "",
      posted_at: "",
      description: "",
      status: 'active',
      // photos: [],
    });
    const quillBar = [['link', 'image' , 'video'],['bold', 'italic', 'underline'],[{ 'list': 'ordered'}, { 'list': 'bullet' }],['code-block'] , ['blockquote'],[{ 'header': 1 }, { 'header': 2 }],[{ 'script': 'sub'}, { 'script': 'super' }],[{ 'size': ['small', false, 'large', 'huge'] }],[{ 'header': [1, 2, 3, 4, 5, 6, false] }],[{ 'color': [] }, { 'background': [] }],[{ 'font': [] }],[{ 'align': [] }],];

    // Functions
    const clearDatas = () => {
      postForm.title = "";
      postForm.category_id = "";
      postForm.sub_title = "";
      postForm.posted_at = "";
      postForm.description = "";
      postForm.status = 'active';
    };
    // Edit Function
    const editOriVals = () => {
      postForm.id = newsMediaDetails.value[0].id;
      postForm.title = newsMediaDetails.value[0].title;
      postForm.category_id = newsMediaDetails.value[0].category_id;
      postForm.sub_title = newsMediaDetails.value[0].sub_title;
      postForm.posted_at = newsMediaDetails.value[0].posted_at;
      postForm.description = newsMediaDetails.value[0].description;
      postForm.status = newsMediaDetails.value[0].status;
    };

    //Datas Fetching
    const fetchDetails = () => {
      spinner.value = true;
      store
        .dispatch("app-mypages/fetchDetails", {
          path: "/our_news",
          id: routeId.value,
        })
        .then((response) => {
          newsMediaDetails.value = [response.data.data];
          spinner.value = false;
        });
    };
    fetchDetails();
    const fetchCategories = () => {
      store
        .dispatch("app-mypages/fetchDatas", "/our_news_categories")
        .then((response) => {
          categories.value = response.data.data.data;
        });
    };
    fetchCategories();

    // Update Photos
    const uploadUpdatePhoto = (updatePhotoIds) => {
      updatePhotoArr.value = updatePhotoIds;
      postForm.photos = updatePhotoArr.value;
    };
    const updatePhoto = () => {
      spinner.value = true;
      editOriVals();
      store
        .dispatch("app-mypages/updatePhotoArr", {
          path: `/our_news/${newsMediaDetails.value[0].id}`,
          payload: postForm,
        })
        .then((response) => {
          fetchDetails();
          spinner.value = false;
          myModal.value = false;
          editDelModal.value = false;
          isEdit.value = false;
          clearDatas();
        });
    };

    // Data Editing
    const editNewsMediaData = () => {
      editDelModal.value = true;
      isEdit.value = true;
      editOriVals();
    };

    const updateNewsMediaData = () => {
      spinner.value = true;
      // console.log("okay1")
      // console.log(postForm)
      store
        .dispatch("app-mypages/updateData", {
          path: "/our_news",
          payload: postForm,
        })
        .then((response) => {
          console.log("okay2")
          fetchDetails();
          spinner.value = false;
          editDelModal.value = false;
          isEdit.value = false;
          clearDatas();
        });
    };

    // Data Deleting
    const delNewsMediaData = () => {
      spinner.value = true;
      editDelModal.value = false;
      store
        .dispatch("app-mypages/delData", {
          path: "/our_news",
          id: newsMediaDetails.value[0].id,
        })
        .then((response) => {
          spinner.value = false;
          router.go(-1);
        });
    };

    //Functions
    const toggle = (c) => {
      c ? (postForm.status = 'active') : (postForm.status = "unactive");
      // console.log(postForm.status);
    };

    const close = () => {
      editDelModal.value = false;
      pendingModal.value = false;
      myModal.value = false;
      // isEdit.value = false;
      isPhoto.value = false;
      clearDatas();
    };

    return {
      spinner,
      myModal,
      editDelModal,
      pendingModal,
      fetchDetails,
      postForm,
      editOriVals,
      isEdit,
      isPhoto,
      uploadUpdatePhoto,
      updatePhoto,
      newsMediaDetails,
      toggle,
      checked,
      postForm,
      categories,
      editNewsMediaData,
      updateNewsMediaData,
      delNewsMediaData,
      router,
      close,
      quillBar
    };
  },
};
</script>
  
<style scoped>
.text-underline {
  text-decoration: underline;
}
</style>
  